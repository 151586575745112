import { computed, inject, ref } from 'vue';
import type { ChangeProfileCustomField } from '~/types/models/profile';
import { useUser, useSessionContext } from '#imports';
import { useI18n } from 'vue-i18n';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';

/**
 * Button logic to update redirectHomePage custom field
 * */
export function useCustomHomepage() {
    const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
    const { user, isLoggedIn } = useUser();
    const { pushSuccess, pushError } = useNotifications();
    const { t } = useI18n();
    const { foreignKey } = useNavigationContext();
    const { refreshSessionContext } = useSessionContext();
    const { updatePersonalInfo } = useUser();

    // @ts-ignore
    const redirectHomePage = computed(() => user.value?.customFields?.redirectHomePage);
    const loading = ref(false);

    /** updates redirectHomePage
     * firstName and lastname are required, that's why we need useUser here and not just taking customField value from context
     * after update we need to refresh sessionContext for the watcher
     * */
    const onUpdateCustomHomepage = async (mode: 'set' | 'unset') => {
        if (isLoggedIn.value) {
            const customer: ChangeProfileCustomField = {
                salutationId: user.value?.salutationId || '',
                firstName: user.value?.firstName || '',
                lastName: user.value?.lastName || '',
                customFields: {
                    redirectHomePage: mode === 'set' ? foreignKey.value : '',
                },
            };

            try {
                loading.value = true;
                await updatePersonalInfo(customer);
                pushSuccess(t('customHomepageUpdateSuccess'));
            } catch (error) {
                pushError(t('errors.message-default'));
                Logger.captureException(error);
            } finally {
                await refreshSessionContext();
                loading.value = false;
            }
        } else {
            offcanvas.open('AccountLoginForm', 'layout-login-offcanvas');
        }
    };

    return {
        onUpdateCustomHomepage,
        loading,
        redirectHomePage,
    };
}

/** gets redirectHomePage customField from sessionContext and returns customHomepagePath  */
export async function getCustomHomepagePath() {
    const { apiClient } = useShopwareContext();
    const { sessionContext } = useSessionContext();
    const { locale } = useI18n();

    let customHomepagePath = '';
    // @ts-ignore
    const redirectHomePage = (sessionContext.value?.customer?.customFields?.redirectHomePage as string) || '';

    if (redirectHomePage) {
        const seoFilters = [
            {
                field: 'foreignKey',
                type: 'equals',
                value: redirectHomePage,
            },
        ];

        const languageId = sessionContext.value?.salesChannel?.languageId;
        if (languageId) {
            seoFilters.push({
                field: 'languageId',
                type: 'equals',
                value: languageId,
            });
        }

        const categorySeoUrls = await apiClient.invoke('readSeoUrl post /seo-url', {
            filter: seoFilters,
        });

        if (categorySeoUrls.elements.length > 0) {
            const seoPathInfo = categorySeoUrls.elements[0]?.seoPathInfo;
            customHomepagePath = `/${locale.value}/${seoPathInfo}`;
        } else {
            customHomepagePath = `/${locale.value}/navigation/${redirectHomePage}`;
        }
    }

    return {
        customHomepagePath,
    };
}
